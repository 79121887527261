import React from "react";
import "./index.css";

function gotoTOS() {
  window.open(
    "https://insightreader.notion.site/Terms-of-Service-12e8922cf506804bb265c0d2c6225b18",
    "_blank"
  );
}

function gotoPP() {
  window.open(
    "https://insightreader.notion.site/Privacy-Policy-12e8922cf506805c983bcbe40f38ebbd",
    "_blank"
  );
}

function gotoFB() {
  window.open(
    "https://www.facebook.com/profile.php?id=61567867177201",
    "_blank"
  );
}

function gotoDiscord() {
  window.open("https://discord.gg/R7hJzFZ3a7", "_blank");
}

function gotoInsta() {
  window.open("https://www.instagram.com/InsightReader.ai", "_blank");
}

function gotoX() {
  window.open("https://x.com/insightreaderai", "_blank");
}

const IntroSection = () => {
  return (
    <div className="IntroContainer">
      <div className="IntroLines"></div>
      <div className="LinkRows">
        <div className="TextItem">© Insight Reader 2024</div>
        <div className="LinkSep">|</div>
        <div className="LinkItem" onClick={gotoTOS}>
          Terms of Service
        </div>
        <div className="LinkSep">|</div>
        <div className="LinkItem" onClick={gotoPP}>
          Privacy Policies
        </div>
      </div>
      <div className="IconLines">
        <div className="IconImg" onClick={gotoDiscord}>
          <img
            src="../icons/discord.png"
            alt="discord"
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <div className="IconImg" onClick={gotoFB}>
          {" "}
          <img
            src="../icons/facebook.png"
            alt="facebook"
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <div className="IconImg" onClick={gotoInsta}>
          {" "}
          <img
            src="../icons/insta.png"
            alt="instagram"
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <div className="IconImg" onClick={gotoX}>
          {" "}
          <img
            src="../icons/twitterx.png"
            alt="twitter"
            style={{ width: "25px", height: "25px", marginTop: "2px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
