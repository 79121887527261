class LocalStorageWithExpiry {
  constructor() {
    this.prefix = "reactApp_";
  }

  setWithExpiry(key, value, ttl) {
    const now = new Date().getTime();
    const item = {
      value: value,
      expiry: now + ttl,
    };
    localStorage.setItem(this.prefix + key, JSON.stringify(item));
  }

  getItem(key) {
    const itemStr = localStorage.getItem(this.prefix + key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    return item.value;
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(this.prefix + key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date().getTime();
    if (now > item.expiry) {
      localStorage.removeItem(this.prefix + key);
      return null;
    }
    return item.value;
  }
}

export default LocalStorageWithExpiry;
