import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Copyright from "../internals/components/Copyright.js";
import BookItem from "./BookItem.js";
import Loading from "./Loading.js";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";

export default function PanelSearch(props) {
  const [book, setBook] = React.useState(null);

  useEffect(() => {
    // 总是去搜索最新的数据
    fetch("/api/db/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ keywords: props.keywords }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (jsonData.code === 0) {
          if (jsonData.data === null) {
            setBook([]);
          } else {
            setBook(jsonData.data);
          }
        } else {
          console.error("Error fetching JSON:", jsonData.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  }, [props.keywords]);

  return (
    <Box
      sx={{
        width: "95%",
        maxWidth: { sm: "95%", md: "1700px" },
        marginLeft: "30px",
        marginTop: "30px",
        marginRight: "30px",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: "30px" }}>
        Search Result
      </Typography>
      <Grid
        container
        spacing={6}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(5) }}
      >
        {book === null && <Loading />}
        {book && book.length === 0 && <Typography>No result</Typography>}
        {book &&
          book.map((item, index) => (
            <BookItem
              key={item.ID}
              ID={item.ID}
              PhotoURL={item.Photo}
              Title={item.Title}
              BookCount={item.BookCount}
              Author={item.Author}
            />
          ))}
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
