import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Copyright from "../internals/components/Copyright.js";
import BookItem from "./BookItem.js";
import Loading from "./Loading.js";
import LocalStorageWithExpiry from "../../../utils/storage.js";
import { useEffect } from "react";

const storage = new LocalStorageWithExpiry();

// 书单列表页，通过书单 ID 获取书单详情
export default function PanelBookList(props) {
  const [collInfo, setCollInfo] = React.useState(null);

  useEffect(() => {
    var mybook = storage.getWithExpiry("collInfo" + props.ID);
    if (mybook) {
      var jsonmybook = JSON.parse(mybook);
      setCollInfo(jsonmybook);
    } else {
      setCollInfo(null);
      fetch("/api/db/collbooks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: props.ID }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          setCollInfo(jsonData.data);
          storage.setWithExpiry(
            "collInfo" + props.ID,
            JSON.stringify(jsonData.data),
            600 * 1000 // 10 minutes
          );
        })
        .catch((error) => {
          console.error("Error fetching JSON:", error);
        });
    }
  }, [props.ID]);

  return (
    <Box
      sx={{
        width: "95%",
        maxWidth: { sm: "95%", md: "1700px" },
        marginLeft: "30px",
        marginTop: "30px",
        marginRight: "30px",
      }}
    >
      {collInfo === null && <Loading />}
      {collInfo && (
        <Grid
          container
          spacing={6}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(20) }}
        >
          {collInfo.map((item, index) => (
            <BookItem
              key={item.ID}
              ID={item.ID}
              PhotoURL={item.Photo}
              Title={item.Title}
              BookCount={item.BookCount}
              Author={item.Author}
            />
          ))}
        </Grid>
      )}
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
