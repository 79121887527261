import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const gotoEmail = () => {
    window.location.href = "mailto:contact@insightreader.ai";
  };

  const gotoDiscord = () => {
    window.open("https://discord.gg/R7hJzFZ3a7", "_blank");
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: "text.primary",
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What can Insight Reader do for me?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "80%" } }}
            >
              Insight Reader is here to help you learn faster and smarter.{" "}
              <br />
              It offers summaries from thousands of bestselling non-fiction
              books to read or listen to in just 15 minutes. <br />
              Whether you want to acquire new knowledge or fill up your waiting
              times with insightful information, Insight Reader is your perfect
              learning companion.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How can I use Insight Reader?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "80%" } }}
            >
              You can access Insight Reader on most devices. <br />
              Either download our Insight Reader app on your smartphone,
              available on both iOS and Android devices, or use it on any
              desktop computer through our website. <br />
              For on-the-go convenience, listen to our audio versions while
              commuting, during your workouts, or while doing household chores.
              <br />
              You also have the option to read or listen offline by downloading
              the content. <br />
              To make your journeys even more enlightening, you can use Insight
              Reader through CarPlay and Android Auto while you're driving.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What types of books does Insight Reader cover? How many are there?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "80%" } }}
            >
              There are currently over 28,000 books in the Insight Reader
              library and we constantly add more titles to our collection. From
              the latest releases to timeless classics, Insight Reader's library
              spans a diverse range of topics, including but not limited to
              business, personal development, parenting, science, philosophy,
              psychology, politics, and culture. <br />
              Our goal is to provide key insights from globally acclaimed and
              impactful non-fiction books. Whether you want to dive deep into a
              specific subject or explore various topics, you'll find the right
              content that suits your needs and interests.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What makes Insight Reader book summaries the best on the market?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "80%" } }}
            >
              Insight Reader book summaries stand out for their commitment to
              quality. We work with experts, writers, and editors who
              collaborate with the original authors whenever possible to ensure
              that the summaries contain the essential insights and preserve the
              spirit of the entire book. <br />
              Besides, Insight Reader provides an audio version for each
              summary, giving you the flexibility to learn however you want.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Have more questions? Contact our Customer Support!
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "80%" } }}
            >
              You can reach us via{" "}
              <Link style={{ cursor: "pointer" }} onClick={gotoEmail}>
                contact@insightreader.ai
              </Link>
              <br />
              Join our{" "}
              <Link style={{ cursor: "pointer" }} onClick={gotoDiscord}>
                Discord Community
              </Link>{" "}
              to get help from our team and other.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
