import * as React from "react";
import Box from "@mui/material/Box";

import { MyContext } from "./MyContext";
import { useContext } from "react";
import PanelForYou from "./PanelForYou";
import PanelExplorer from "./PanelExplorer";
import PanelMyLib from "./PanelMyLib";
import PanelSettings from "./PanelSettings";
import PanelProfile from "./PanelProfile";
import PanelAccount from "./PanelAccount";
import PanelBookInfo from "./PanelBookInfo";
import PanelBookList from "./PanelBookList";
import PanelSearch from "./PanelSearch";
import PageSignin from "../../Signin";
import PageSignup from "../../Signup";
import { useLocation } from "react-router-dom";

export default function TabContent(props) {
  const {
    curTitle,
    curBookID,
    curCollectionID,
    setCurBookID,
    setCurTitle,
    setCurCollectionID,
  } = useContext(MyContext);
  const location = useLocation();

  React.useEffect(() => {
    // 如果传进来有 BookID，就设置当前的 BookID
    if (props.bookID !== undefined && props.bookID !== "") {
      console.log("TabContent: setCurBookID: ", props.bookID);
      setCurTitle("");
      setCurBookID(props.bookID);
    } else if (props.keywords !== undefined && props.keywords !== "") {
      console.log("TabContent: setCurCollectionID: ", props.keywords);
      setCurTitle("Search");
    } else {
      console.log("TabContent: location.pathname: ", location.pathname);
      const pathSegments = location.pathname.split("/"); // 分割路径
      const lastSegment = pathSegments[pathSegments.length - 1]; // 获取最后一段
      if (lastSegment === "mylib") {
        setCurBookID("");
        setCurCollectionID("");
        setCurTitle("My Library");
      } else if (lastSegment === "explore") {
        setCurBookID("");
        setCurCollectionID("");
        setCurTitle("Explore");
      } else if (lastSegment === "foryou") {
        setCurBookID("");
        setCurCollectionID("");
        setCurTitle("For You");
      } else if (lastSegment === "account") {
        setCurBookID("");
        setCurCollectionID("");
        setCurTitle("Account");
      } else if (lastSegment === "profile") {
        setCurBookID("");
        setCurCollectionID("");
        setCurTitle("Profile");
      }
    }
  }, [
    props.curTitle,
    props.bookID,
    props.keywords,
    setCurBookID,
    setCurTitle,
    setCurCollectionID,
    location,
  ]);

  return (
    <Box>
      {curTitle === "For You" && <PanelForYou />}
      {curTitle === "Explore" && <PanelExplorer />}
      {curTitle === "My Library" && <PanelMyLib />}
      {curTitle === "Settings" && <PanelSettings />}
      {curTitle === "Profile" && <PanelProfile />}
      {curTitle === "Account" && <PanelAccount />}
      {curTitle === "Signin" && <PageSignin />}
      {curTitle === "Signup" && <PageSignup />}
      {curTitle === "Search" && <PanelSearch keywords={props.keywords} />}
      {curBookID !== "" && <PanelBookInfo ID={curBookID} />}
      {curCollectionID !== "" && <PanelBookList ID={curCollectionID} />}
    </Box>
  );
}
