import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import Loading from "./Loading";
import LocalStorageWithExpiry from "../../../utils/storage.js";
import CollItem from "./CollItem";
import { NextArrow, PrevArrow } from "./SlickArrow.js";

const storage = new LocalStorageWithExpiry();

export default function RecCollections(props) {
  const [coll, setColl] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    var mycoll = storage.getWithExpiry("collection");
    if (mycoll) {
      var jsoncoll = JSON.parse(mycoll);
      console.log("get collection from storage: ", jsoncoll);
      var randomData = jsoncoll.sort(() => Math.random() - 0.5).slice(0, 9);
      setColl(randomData);
    } else {
      console.log("no collection in storage");
      setColl(null);
      // 请求网络数据
      fetch("/api/db/collections", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((jsonData) => {
          // 从 data 中随机选 9 个
          var randomData = jsonData.data
            .sort(() => Math.random() - 0.5)
            .slice(0, 9);
          setColl(randomData);
          storage.setWithExpiry(
            "collection",
            JSON.stringify(jsonData.data),
            600 * 1000 // 10 minutes
          );
        })
        .catch((error) => {
          console.error("Error fetching JSON:", error);
        });
    }
  }, []);

  return (
    <Box className="slider-container">
      {coll === null && <Loading />}
      {coll && (
        <Slider {...settings}>
          {coll.map((item, index) => (
            <CollItem
              key={item.ID}
              ID={item.ID}
              PhotoURL={item.Photo}
              Title={item.Title}
              BookCount={item.BookCount}
              SubTitle={item.SubTitle}
            />
          ))}
        </Slider>
      )}
    </Box>
  );
}
