import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./CollItem.css";
import { useContext } from "react";
import { MyContext } from "./MyContext";

const CollItem = (props) => {
  const { setCurTitle, setCurBookID, setCurCollectionID } =
    useContext(MyContext);
  const handleClick = () => {
    setCurBookID("");
    setCurTitle(props.Title);
    setCurCollectionID(props.ID);
  };

  return (
    <Box className="RecContainer">
      <img
        className="RecPhoto"
        src={props.PhotoURL}
        alt="PhotoRec"
        onClick={handleClick}
      />
      <Typography
        component="h2"
        variant="subtitle2"
        gutterBottom
        className="RecBookTitle"
        sx={{ fontWeight: "600" }}
      >
        {props.Title}
      </Typography>
      <Typography className="RecSubtitle" sx={{ color: "text.secondary" }}>
        {" "}
        {props.SubTitle}{" "}
      </Typography>
    </Box>
  );
};

export default CollItem;
