import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: "Customizable Reading Experience",
    description:
      "Tailor your reading environment to suit your preferences! Adjust font sizes, styles, and background colors to create a comfortable reading experience. Whether you prefer a light mode for daytime reading or a dark mode for late-night sessions, our customizable settings ensure your eyes stay relaxed.",
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: "Bookmark and Annotation Features",
    description:
      "Never lose your place again! Easily bookmark your favorite passages and make annotations directly in the text. Highlight important quotes, jot down thoughts, or create study notes that you can revisit anytime. Enhance your understanding and retention of what you read!",
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: "Sync Across Devices",
    description:
      "Read seamlessly across all your devices! Start a book on your tablet during your commute and pick up right where you left off on your smartphone or computer. Our cloud sync feature ensures your bookmarks, annotations, and reading progress are always up to date.",
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: "Built-in Dictionary and Thesaurus",
    description:
      "Expand your vocabulary with ease! Our built-in dictionary and thesaurus allow you to look up unfamiliar words instantly without leaving the page. Enhance your comprehension and enrich your reading experience with just a tap.",
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: "Offline Reading Mode",
    description:
      "Enjoy your favorite titles anytime, anywhere! Download eBooks for offline reading, so you can dive into your stories without needing an internet connection. Perfect for travel, commuting, or relaxing in a cozy spot without Wi-Fi.",
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: "Community and Book Recommendations",
    description:
      "Join a vibrant community of readers! Share your thoughts, reviews, and recommendations with fellow book lovers. Our personalized book suggestions based on your reading habits help you discover new favorites and hidden gems tailored just for you.",
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Experience the joy of reading anytime, anywhere, with our
            user-friendly interface and personalized recommendations tailored
            just for you.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: "inherit",
                  p: 3,
                  height: "100%",
                  borderColor: "hsla(220, 25%, 25%, 0.3)",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: "medium" }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
