import * as React from "react";
import Box from "@mui/material/Box";
import "./SitemarkIcon.css";

export default function SitemarkIcon() {
  return (
    <Box
      sx={{ height: 21, width: 140, mr: 2, cursor: "pointer" }}
      onClick={() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth", // 平滑滚动
        });
      }}
    >
      <img src="banner.png" alt="Sitemark" className="SiteLogo" />
    </Box>
  );
}
