import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import OutlinedInput from "@mui/material/OutlinedInput";
import Cookies from "js-cookie";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function ForgotPassword({ open, handleClose }) {
  const [openTip, setOpenTip] = React.useState(false);
  const [tipText, setTipText] = React.useState("");
  const [tipType, setTipType] = React.useState("warning");

  const handleTipClose = () => {
    setOpenTip(false);
  };

  function handleSubmit() {
    const email = document.getElementById("email").value;
    fetch("/api/forgotpwd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("Success:", data);
        if (data.code === 0) {
          setTipText(
            "Request Successful! Please check your email for the reset link."
          );
          setTipType("success");
          setOpenTip(true);
          setTimeout(() => {
            handleClose();
          }, 3000);
        } else {
          setTipText("Request failed: " + data.message);
          setTipType("error");
          setOpenTip(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setTipText("Request failed: " + error);
        setTipType("error");
        setOpenTip(true);
      });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { backgroundImage: "none" },
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        open={openTip}
        autoHideDuration={6000}
        onClose={handleTipClose}
      >
        <Alert
          onClose={handleTipClose}
          severity={tipType}
          variant="filled"
          sx={{
            width: "100%",
          }}
        >
          {tipText}
        </Alert>
      </Snackbar>
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
      >
        <DialogContentText>
          Enter your account&apos;s email address, and we&apos;ll send you a
          link to reset your password.
        </DialogContentText>
        <OutlinedInput
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label="Email address"
          placeholder="Email address"
          type="email"
          fullWidth
          defaultValue={Cookies.get("useremail") || ""}
        />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;
