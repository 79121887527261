import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "../internals/components/Copyright";

import TrendingList from "./TrendingList";
import LatestList from "./LatestList";
import LatestCollections from "./LatestCollections";

export default function PanelExplore() {
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Trending
      </Typography>
      <Box
        sx={{ marginLeft: "50px", marginBottom: "50px", marginRight: "50px" }}
      >
        <TrendingList />
      </Box>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Latest
      </Typography>
      <Box
        sx={{ marginLeft: "50px", marginBottom: "50px", marginRight: "50px" }}
      >
        <LatestList />
      </Box>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Latest collections
      </Typography>
      <Box
        sx={{ marginLeft: "50px", marginBottom: "50px", marginRight: "50px" }}
      >
        <LatestCollections />
      </Box>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
