import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "../internals/components/Copyright";

export default function PanelForYou() {
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "600px" } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Settings
      </Typography>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
