import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./common.css";
import Box from "@mui/material/Box";
import Loading from "./Loading.js";
import LocalStorageWithExpiry from "../../../utils/storage.js";
import BookItem from "./BookItem.js";
import { NextArrow, PrevArrow } from "./SlickArrow.js";

const storage = new LocalStorageWithExpiry();

export default function LatestList() {
  const [book, setBook] = useState(null);

  const settings = {
    dots: false, // 下面没有点点
    infinite: true, // 无限循环
    speed: 500, // 滑动速度
    slidesToShow: 3, // 一次显示 3 个
    slidesToScroll: 3, // 一次滑动 3 个
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    var mycoll = storage.getWithExpiry("booklastest");
    if (mycoll) {
      var jsoncoll = JSON.parse(mycoll);
      console.log("get book from storage: ", jsoncoll);
      var randomData = jsoncoll.sort(() => Math.random() - 0.5).slice(0, 9);
      console.log("set book data from storage: ", randomData);
      setBook(randomData);
    } else {
      console.log("no book in storage");
      setBook(null);
      // 请求网络数据
      fetch("/api/db/booklist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pageSize: 30, pageIndex: 1 }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          // 从 data 中随机选 9 个
          var randomData = jsonData.data
            .sort(() => Math.random() - 0.5)
            .slice(0, 9);
          console.log("set book data from network: ", randomData);
          setBook(randomData);
          storage.setWithExpiry(
            "booklastest",
            JSON.stringify(jsonData.data),
            600 * 1000 // 10 minutes
          );
        })
        .catch((error) => {
          console.error("Error fetching JSON:", error);
        });
    }
  }, []);

  return (
    <Box className="slider-container">
      {book === null && <Loading />}
      {book && (
        <Slider {...settings}>
          {book.map((item, index) => (
            <BookItem
              key={item.ID}
              ID={item.ID}
              PhotoURL={item.Photo}
              Title={item.Title}
              BookCount={item.BookCount}
              Author={item.Author}
            />
          ))}
        </Slider>
      )}
    </Box>
  );
}
