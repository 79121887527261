import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "../internals/components/Copyright";
import RecBooks from "./RecBooks";
import RecCollections from "./RecCollections";

export default function PanelForYou() {
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1000px" } }}>
      {/* Recommended Books */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Recommended for you
      </Typography>
      <Box
        sx={{ marginLeft: "50px", marginBottom: "50px", marginRight: "50px" }}
      >
        <RecBooks />
      </Box>

      {/* Recommended Collections */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Collections for you
      </Typography>
      <Box
        sx={{ marginLeft: "50px", marginBottom: "50px", marginRight: "50px" }}
      >
        <RecCollections />
      </Box>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
