import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import App from "./App";
import AppRouter from "./AppRouter"; // 引入 AppRouter 组件
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";

ReactGA.initialize("G-4JKY926QTY");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppRouter />);

/*function sendToAnalytics({ id, name, value }) {
  ReactGA("send", "event", {
    eventCategory: "Web Vitals",
    eventAction: name,
    eventValue: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  });
}*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
