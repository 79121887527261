import React from "react";
import { CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <CircularProgress
      className="Loading"
      sx={{ width: "10px", height: "10px" }}
    />
  );
};

export default Loading;
