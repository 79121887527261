import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./BookItem.css";
import { useContext } from "react";
import { MyContext } from "./MyContext";
import { useNavigate } from "react-router-dom";

const BookItem = (props) => {
  const { setCurTitle, setCurBookID, setCurCollectionID } =
    useContext(MyContext);
  const navigate = useNavigate();
  const handleClick = () => {
    setCurCollectionID("");
    setCurTitle(props.Title);
    setCurBookID(props.ID);
    navigate(`/book/${props.ID}`);
  };

  return (
    <Box className="RecBookContainer">
      <img
        className="RecBookPhoto"
        src={props.PhotoURL}
        alt="PhotoRec"
        onClick={() => handleClick(props.ID)}
      />
      <Typography
        component="h2"
        variant="subtitle2"
        gutterBottom
        className="RecBookTitle"
        sx={{ fontWeight: "600" }}
      >
        {props.Title}
      </Typography>
      <Typography className="RecBookSubtitle" sx={{ color: "text.secondary" }}>
        {props.Author}
      </Typography>
    </Box>
  );
};

export default BookItem;
