import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/system";

const userTestimonials = [
  {
    avatar: <Avatar alt="Mark Sharp" src="/avatar/1.jpg" />,
    name: "Mark Sharp",
    occupation: "Senior Engineer",
    testimonial:
      "I've tried several eBook readers, but Insight Reader stands out with its intuitive interface and powerful annotation tools. The built-in dictionary is a game-changer—I can look up words without interrupting my flow. The community features also allow me to connect with other readers and share recommendations. It's truly a reader's paradise!",
  },
  {
    avatar: <Avatar alt="David Howard" src="/avatar/2.jpg" />,
    name: "David Howard",
    occupation: "Lead Product Designer",
    testimonial:
      "I love the personalized book recommendations from Insight Reader! It feels like the app knows my taste in books. I've discovered so many new authors and genres I wouldn't have picked up otherwise. The community discussions are also a fantastic addition, sparking engaging conversations about the books I love. This app has truly enriched my reading life.",
  },
  {
    avatar: <Avatar alt="Sophie Baker" src="/avatar/3.jpg" />,
    name: "Sophie Baker",
    occupation: "CTO",
    testimonial:
      "Insight Reader has made my reading experience so much more enjoyable! The offline mode is perfect for my daily commute, allowing me to dive into my favorite books without needing Wi-Fi. The ability to highlight and annotate directly in the text has helped me retain information better. I can't imagine reading without it now!",
  },
  {
    avatar: <Avatar alt="Emily Stewart" src="/avatar/4.jpg" />,
    name: "Emily Stewart",
    occupation: "Senior Engineer",
    testimonial:
      "As an avid reader, Insight Reader has transformed my reading habits! The customizable settings allow me to adjust the font size and background color, making it easy on my eyes. I love the bookmarking feature, which helps me keep track of my favorite passages. Plus, being able to sync across devices means I can read anywhere without losing my place. Highly recommend it!",
  },
  {
    avatar: <Avatar alt="Travis Howard" src="/avatar/5.jpg" />,
    name: "John Smith",
    occupation: "Product Designer",
    testimonial:
      "Insight Reader has revolutionized my study sessions! The annotation and bookmarking features make it easy to highlight key concepts and jot down notes. The ability to sync my progress across devices means I can study effectively, whether I'm at home or on the go. It's an essential tool for any student or lifelong learner!",
  },
  {
    avatar: <Avatar alt="Cindy Baker" src="/avatar/6.jpg" />,
    name: "Daniel Wolf",
    occupation: "CDO",
    testimonial:
      "I can't say enough good things about Insight Reader! The offline reading feature allows me to download books for long flights, which is a lifesaver. The user-friendly interface makes navigation a breeze, and I appreciate the effort put into the design. It's not just an eBook reader; it's a complete reading experience!",
  },
];

const whiteLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg",
];

const darkLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg",
];

const logoStyle = {
  width: "64px",
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "text.primary" }}
        >
          Testimonials
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          See what our customers love about our products. Discover how we excel
          in efficiency, durability, and satisfaction. Join us for quality,
          innovation, and reliable support.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid
            size={{ xs: 12, sm: 6, md: 4 }}
            key={index}
            sx={{ display: "flex" }}
          >
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: "text.secondary" }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
