import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Search() {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (inputValue.trim() === "") {
        return;
      }
      navigate(`/search/${inputValue}`);
    }
  };

  return (
    <FormControl sx={{ width: { xs: "100%", md: "50ch" } }} variant="outlined">
      <OutlinedInput
        size="small"
        id="search"
        placeholder="Search for Titles, Authors, Collections, etc."
        sx={{ flexGrow: 1 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: "text.primary" }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "search",
        }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={inputValue}
      />
    </FormControl>
  );
}
