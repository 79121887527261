import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/X";
import SitemarkIcon from "./SitemarkIcon";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Copyright() {
  const navigate = useNavigate();

  function gotoAbout() {
    navigate("/about");
  }

  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {"©"}
      &nbsp;{new Date().getFullYear()}&nbsp;
      <Link
        color="text.secondary"
        onClick={gotoAbout}
        sx={{ cursor: "pointer" }}
      >
        Insight Reader
      </Link>
    </Typography>
  );
}

export default function Footer() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [tipText, setTipText] = useState("");
  const [tipType, setTipType] = useState("warning");
  const [starting, setStarting] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false); // 关闭警告提示
  };

  const sendEmail = () => {
    window.location.href = "mailto:contact@insightreader.ai";
  };

  function scrollToElement(elemname) {
    var te = document.getElementById(elemname);
    if (!te) return;
    te.scrollIntoView({
      behavior: "smooth",
    });
  }

  function gotoBlog() {
    window.open(
      "https://insightreader.notion.site/Insight-Reader-Blog-1478922cf50680eca4e4ea048773c96b?pvs=73",
      "_blank"
    );
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      startSubscribe();
    }
  };

  function startSubscribe() {
    // 获取 email-newsletter 的值
    const email = document.getElementById("email-newsletter").value;
    // 检查是否为空
    if (email === "") {
      setTipText("email cannot be empty!");
      setTipType("warning");
      setOpen(true);
      return;
    }
    // 检查是否符合邮箱格式
    var reg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    if (!reg.test(email)) {
      setTipText("email format is incorrect!");
      setTipType("warning");
      setOpen(true);
      return;
    }
    // 发送请求
    setStarting(true);
    fetch("/api/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 0) {
          setTipText("Subscribe Successfully!");
          setTipType("success");
          setOpen(true);
          setStarting(false);
        } else {
          setTipText("Subscribe Failed: " + data.message);
          setTipType("error");
          setOpen(true);
          setStarting(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
        setTipText("Subscribe Failed: " + error);
        setTipType("error");
        setOpen(true);
        setStarting(false);
      });
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={tipType} sx={{ width: "100%" }}>
          {tipText}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <SitemarkIcon />
            <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 600, mt: 2 }}
            >
              Join the newsletter
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
              Subscribe for weekly updates. No spams ever!
            </Typography>
            <InputLabel htmlFor="email-newsletter">Email</InputLabel>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                id="email-newsletter"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Enter your email address"
                placeholder="Your email address"
                slotProps={{
                  htmlInput: {
                    autoComplete: "off",
                    "aria-label": "Enter your email address",
                  },
                }}
                onKeyDown={handleKeyDown}
                sx={{ width: "250px" }}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ flexShrink: 0 }}
                onClick={() => startSubscribe()}
              >
                Subscribe
              </Button>
              <Backdrop
                sx={(theme) => ({
                  color: "#fff",
                  zIndex: theme.zIndex.drawer + 1,
                })}
                open={starting}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Product
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToElement("features")}
          >
            Features
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToElement("testimonials")}
          >
            Testimonials
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToElement("highlights")}
          >
            Highlights
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToElement("pricing")}
          >
            Pricing
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToElement("faq")}
          >
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Company
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/about");
            }}
          >
            About
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={gotoBlog}
          >
            Blog
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Legal
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            href="https://insightreader.notion.site/Terms-of-Service-12e8922cf506804bb265c0d2c6225b18"
            target="_blank"
            style={{ cursor: "pointer" }}
          >
            Terms
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="https://insightreader.notion.site/Privacy-Policy-12e8922cf506805c983bcbe40f38ebbd"
            target="_blank"
            style={{ cursor: "pointer" }}
          >
            Privacy
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={sendEmail}
          >
            Contact
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link
            color="text.secondary"
            variant="body2"
            href="https://insightreader.notion.site/Privacy-Policy-12e8922cf506805c983bcbe40f38ebbd"
            target="_blank"
            style={{ cursor: "pointer" }}
          >
            Privacy Policy
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            href="https://insightreader.notion.site/Terms-of-Service-12e8922cf506804bb265c0d2c6225b18"
            target="_blank"
            style={{ cursor: "pointer" }}
          >
            Terms of Service
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://www.facebook.com/profile.php?id=61567867177201"
            aria-label="Facebook"
            target="_blank"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://x.com/insightreaderai"
            aria-label="X"
            target="_blank"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.instagram.com/InsightReader.ai"
            aria-label="Instagram"
            target="_blank"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
