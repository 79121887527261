import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function Copyright(props) {
  const navigate = useNavigate();

  function gotoAbout() {
    navigate("/about");
  }

  return (
    <Typography
      variant="body2"
      align="center"
      {...props}
      sx={[
        {
          color: "text.secondary",
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      {"© "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" style={{ cursor: "pointer" }} onClick={gotoAbout}>
        Insight Reader
      </Link>
    </Typography>
  );
}
